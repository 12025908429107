import React, { useEffect, useState } from 'react';
import 'swiper/swiper.min.css';

import { Switch, Route, useHistory, useLocation } from 'react-router-dom';

import Error from './pages/Error';

import './App.css';
import Home from './pages/home/Home';
import Navbar from './component/navbar/Navbar';
import Footer from './component/footer/Footer';
import Login from './pages/auth/Login';
import Courses from './pages/course/Courses';
import College from './pages/college/College';
import Purches from './pages/purches/Purches';
import GalleryPage from './pages/gallery/GalleryPage';
import Contact from './pages/contact/Contact';
import CourseDetails from './pages/course/CourseDetails';
import CollegeDetails from './pages/college/CollegeDetails';
import Register from './pages/auth/Register';
import About from './pages/about/About';
import Dashboard from './pages/student/pages/dashboard/Dashboard';

import StudentNavBar from './pages/student/component/navbar/StudentNavBar';
import Student from './pages/student/Student';
import MyDetails from './pages/student/pages/account/MyDetails';
import Setting from './pages/student/pages/account/Setting';
import Wrong from './pages/Wrong';
import ActiveAccount from './pages/auth/ActiveAccount';
import BedEnroll from './pages/enroll/BedEnroll';
import EntrollHone from './pages/enroll/EntrollHone';
import NotApproved from './pages/auth/NotApproved';
import DledEnroll from './pages/enroll/DledEnroll';
import ApplicationSuccess from './pages/success/ApplicationSuccess';
import MyOrders from './pages/student/pages/account/MyOrders';
import ContentDetails from './pages/student/pages/dashboard/ContentDetails';
import MyPayment from './pages/student/pages/account/MyPayment';
import ShippingPolicy from './pages/policy/ShippingPolicy';
import ReturnPolicy from './pages/policy/ReturnPolicy';
import PaidBooks from './pages/purches/PaidBooks';
import BuyNow from './pages/purches/BuyNow';
import PaymentSuccess from './pages/success/PaymentSuccess';
import PaymentFail from './pages/success/PaymentFail';
import BookDetails from './pages/purches/BookDetails';
import ForgetPassword from './pages/auth/ForgetPassword';
import ResetPassword from './pages/auth/ResetPassword';
import PrivacyPolicy from './pages/policy/PrivacyPolicy';
import TermCondition from './pages/policy/TermCondition';
import AccountDet from './pages/student/pages/account/AccountDet';
import { Helmet } from 'react-helmet';
import VerifyOtp from './pages/auth/VerifyOtp';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import EditApplication from './pages/student/pages/account/EditApplication';


const App = () => {
  const loc = useLocation();
  // console.log(loc)
  const [showStudent, setShowStudent] = useState(false);

  useEffect(() => {
    if (window.location.href.toString().includes('/student')) {
      setShowStudent(true);
      // console.log("okk")
    }else{
      setShowStudent(false);
    }
  }, [loc]);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [loc]);



  return (
    <>
    <Helmet>
    <title>Edushark - Learn today to lead tomorrow</title>
                <meta charSet="utf-8" />
                <meta name="description" content="Edushark - Learn today to lead tomorrow. Offering courses in B.Ed, D.El.Ed, and D.Pharm. Start your educational journey with us." />
<meta name="keywords" content="Edushark, learn, education, B.Ed, D.El.Ed, D.Pharm, courses, online learning" />
<meta name="author" content="Edushark" />


<meta name="og:title" content="Edushark - Learn today to lead tomorrow" />
<meta name="og:description" content="Explore courses in B.Ed, D.El.Ed, and D.Pharm. Start your educational journey with Edushark and prepare to lead tomorrow." />
<meta name="og:image" content="https://edushark.in/image/logo.png" />


<meta name="twitter:card" content="summary_large_image" />
 <meta name="twitter:title" content="Edushark - Learn today to lead tomorrow" />
<meta name="twitter:description" content="Explore courses in B.Ed, D.El.Ed, and D.Pharm. Start your educational journey with Edushark and prepare to lead tomorrow." />
<meta name="twitter:image" content="https://edushark.in/image/logo.png" />
            </Helmet>

    {
      showStudent ?<>

      <StudentNavBar/>

            <Switch>
          <Route exact path="/student" component={Student} />
          <Route exact path="/student/dashboard" component={Dashboard} />
         
          <Route exact path="/student/orders" component={MyOrders} />
          <Route exact path="/student/student-basic-details" component={MyDetails} />
          <Route exact path="/student/:coursename/application/:applyid/update" component={EditApplication} />

          <Route exact path="/student/account" component={AccountDet} />

          <Route exact path="/student/payment" component={MyPayment} />
          <Route exact path="/student/setting" component={Setting} />
          <Route exact path="/not-approved" component={NotApproved} />
          <Route exact path="/student/content/:cid" component={ContentDetails} />
          
          <Route exact path="/shipping-policy" component={ShippingPolicy} />
          <Route exact path="/return-policy" component={ReturnPolicy} />
          

          <Route exact path="/something-wrong" component={Wrong} />

          
          <Route component={Error} />
        </Switch>



      </> :<>

      <Navbar />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/active-account" component={ActiveAccount} />
          <Route exact path="/verify-account/:uid" component={VerifyOtp} />

          <Route exact path="/forget-password" component={ForgetPassword} />
          <Route exact path="/reset-password" component={ResetPassword} />

          <Route exact path="/student" component={Student} />

          <Route exact path="/courses" component={Courses} />
          <Route exact path="/courses/:coursid"  component={CourseDetails} />

          <Route exact path="/courses/:coursid/enroll"  component={EntrollHone} />


          <Route exact path="/colleges" component={College} />      

          <Route exact path="/colleges/:clgid"  component={CollegeDetails} />

          {/* <Route exact path="/purchase" component={Purches} /> */}
          <Route exact path="/purchase-books" component={PaidBooks} />
          <Route exact path="/purchase-books/:bookid/buynow" component={BuyNow} />
          <Route exact path="/purchase-books/:bookid/:bookname" component={BookDetails} />



          <Route exact path="/gallery" component={GalleryPage} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/aboutus" component={About} />
          <Route exact path="/something-wrong" component={Wrong} />
          <Route exact path="/enroll/BED-course" component={BedEnroll} />
          <Route exact path="/enroll/DLED-course" component={DledEnroll} />
          <Route exact path="/application-success" component={ApplicationSuccess} />
         
          <Route exact path="/not-approved" component={NotApproved} />
          <Route exact path="/shipping-policy" component={ShippingPolicy} />
          <Route exact path="/return-policy" component={ReturnPolicy} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/terms-and-conditions" component={TermCondition} />

          

          <Route exact path="/success" component={PaymentSuccess} />
          <Route exact path="/failure" component={PaymentFail} />



          


          
          <Route component={Error} />
        </Switch>
        <Footer />


      </>
    }
    <div className='page_what_link'>
      <a href="https://wa.me/+919749491137?text=I have a query." target="_blank">
      <div className="page_what" >
        <i className='fa fa-whatsapp'></i>
        </div>
      </a>
    </div>
      


        
      
    </>
  );
};

export default App;
