import React from "react";
import "./Home.css";
import { path } from "../../data/fdata";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import BigText from "./BigText";
import { CircularProgress } from '@mui/material'

const AfterHero = ({courseList}) => {
  return (
    <>
      <div className="afterhero">
        <div className="container-fluid">
          <h1>Learn the secrets to Life Success</h1>
          <div className="row mt-5">
            <div className="col-lg-11 col-md-12 col-sm-12 col-10 mx-auto">
              <div className="row">
                <div className="col-lg-3 col-md-4 col-sm-6  mb-3 afterhero_card">
                  <div className="p-2 text-center">
                    <img
                      src={`${path}/image/expert.png`}
                      alt=""
                      className="icon_img"
                    />
                    <h5>Expert Teacher</h5>
                    <p>
                      Develop skills for career of various majors including
                      computer
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6  mb-3 afterhero_card">
                  <div className="p-2 text-center">
                    <img
                      src={`${path}/image/growth.png`}
                      alt=""
                      className="icon_img"
                    />
                    <h5>Self Development</h5>
                    <p>
                      Develop skills for career of various majors including
                      computer
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6  mb-3 afterhero_card">
                  <div className="p-2 text-center">
                    <img
                      src={`${path}/image/online-learning.png`}
                      alt=""
                      className="icon_img"
                    />
                    <h5>Remote Learning</h5>
                    <p>
                      Develop skills for career of various majors including
                      computer
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6  mb-3 afterhero_card">
                  <div className="p-2 text-center">
                    <img
                      src={`${path}/image/customer-service.png`}
                      alt=""
                      className="icon_img"
                    />
                    <h5>Life Time Support</h5>
                    <p>
                      Develop skills for career of various majors including
                      computer
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <BigText/>

        <div className="container-fluid   pt-lg-5 pt-md-0 ">
          <div className="row mt-5">
            <div className="col-lg-11 col-md-12 col-sm-12 col-12 mx-auto">
              <div className="row">
                <div className="col-lg-6 col-md-12 mb-3 access_box order-lg-1 order-md-2 order-2">
                  <h1>Get Instant Access To Expert solution</h1>
                  <p>The ultimate planning solution for students who want to reach their personal goals. Effortless comfortable eye-catching unique detail</p>
                  <div className="acces_flex_items">
                    <div className="acces_flex_item">
                        <img
                          src={`${path}/image/signup.svg`}
                          alt=""
                          className=""
                        />
                        <div>
                            <h5>Sign up in website</h5>
                            <p>Please complete the sign-up process to gain access to all its features and content</p>
                        </div>
                     
                    </div>
                    <div className="acces_flex_item">
                        <img
                          src={`${path}/image/enroll.svg`}
                          alt=""
                          className=""
                        />
                        <div>
                            <h5>Enroll your course</h5>
                            <p>You need to enroll in the course before the registration deadline to secure your spot in the class.</p>
                        </div>
                     
                    </div>
                    <div className="acces_flex_item">
                        <img
                          src={`${path}/image/watch.svg`}
                          alt=""
                          className=""
                        />
                        <div>
                            <h5>Start from now</h5>
                            <p>Let's begin our journey right away, making the most of every moment from this point forward.</p>
                        </div>
                     
                    </div>

                  </div>
                </div>
                <div className="col-lg-6 col-md-12 mb-3 order-lg-2 order-md-1 order-1">
                  <img
                    src={`${path}/image/access.jpg`}
                    alt=""
                    className="access_img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid mt-5  pt-lg-5 pt-md-0 ">
          <div className="row mt-5">
            <div className="col-lg-11 col-md-12 col-sm-12 col-12 mx-auto">
              <div className="row">
                
                <div className="col-lg-6 col-md-12 mb-3 access_rel">
                  <img
                    src={`${path}/image/about.png`}
                    alt=""
                    className="access_img"
                  />
                   <div className='access_hero_img_box_inner_left'>
                      
                      <div className='text-dark'>
                        <h5 className='fn_14 d-flex justify-content-center align-items-center '><i className='fa fa-star text-warning fa-2x'></i> 4.5 (3.4k Reviews)</h5>
                        <p className='m-0 ml-3 fn_12'>Great Courses</p>
                      </div>
                    </div>
                    <div className='access_hero_img_box_inner_right_top'>
                      
                      <div className='text-dark'>
                        <h5 className='fn_14 d-flex justify-content-center align-items-center '><i className='fa fa-star text-warning fa-2x'></i> 4.0 (3.4k Reviews)</h5>
                        <p className='m-0 ml-3 fn_12'>Advanced study material</p>
                      </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 mb-3 access_box ">
                  <h1>Online Course can be Tailored to Need of learners</h1>
                  <p className="my-4">The ultimate planning solution for students who want to reach their personal goals.Effortless comfortable eye-catching unique detail</p>
                  <ul>
                    <li>Expert Instructors</li>
                    <li>Interactive Learning</li>
                    <li>100% Transparent Fee Structure</li>
                    <li>No Hidden Charges</li>
                    <li>Facility To Pay Fee In Five Installment</li>
                    <li>Instant Receipt Copy For Every Payment</li>
                    <li>Copy Of Course Fee Aggrement</li>
                    
                  </ul>
                  <div className="text-left mt-4">
                    <NavLink to="/aboutus" className='btn btn-warning'>Know More</NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid mt-5  pt-lg-5 pt-md-0 ">
          <div className="row mt-5">
            <div className="col-lg-11 col-md-12 col-sm-12 col-12 mx-auto">
              <h1>Our Courses</h1>
              <div className="row mt-5">
                
                {
                  courseList.length > 0 ?(
                    <>
                    {
                  courseList.filter((v)=>v.cou_isSeen==true).map((val,ind)=>{
                    return(
                      <>
                      <div className="col-xl-4 col-lg-6 col-md-6 col-sm-10 col-12 mb-4 mx-auto ">
                  <div className="card p-2">
                    {/* <img src={val.cou_image} alt="" className="courseimg" /> */}
                    <div className="div_box">
                    <img src={val.cou_image} alt="" className="courseimg" />
                    </div>
                    <div className="p-2 text-center">
                    <h3 className="text-size">{val.cou_name}</h3>
                  <p className="short_p">{val.cou_description}</p>
                   
                  <div className="text-center mt-4">
                    <NavLink to={`/courses/${val._id}`} className='btn btn-warning '>Know More & Enroll</NavLink>
                  </div>
                    </div>
                  </div>
                </div>
                      </>
                    )
                  })
                }
                    
                    </>
                  ):(
                    <>
                    <div className="mx-auto" >

                    <CircularProgress />
                    
                    </div>
                    
                    
                    </>
                  )
                }
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AfterHero;
